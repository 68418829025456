@import './colorsAndConstants.less';


// Typography -------------------------------

.hero-title {
  font-weight: 600;
  font-size: 34px;
  line-height: normal;
  color: @gray800;
}

.light-text {
  color: #96a0b5;
  font-size: 16px;
  font-weight: 500;
}

.sub-text {
  color: #445880;
  font-size: 14px;
  font-weight: 300;
}

.bigger-text {
  font-size: 16px;
}

.bold-text {
  font-weight: 500;
}

.bold-sub-text {
  font-weight: 500;
  font-size: 12px;
}

.sub-heading {
  font-weight: 500;
  font-size: 20px;
}

.sub-heading-light {
  font-weight: 400;
  font-size: 20px;
}

.large-text {
  font-weight: 400;
  font-size: 18px;
}

.bold-text {
  font-weight: 500;
}

.heading-2 {
  font-weight: 500;
  font-size: 32px;
}

.heading-2-light {
  font-weight: 400;
  font-size: 32px;
}

.heading-3 {
  font-weight: 600;
  font-size: 28px;
}

.heading-5 {
  font-weight: 600;
  font-size: 24px;
}

.heading-5-light {
  font-weight: 500;
  font-size: 24px;
}

.small-sub-text {
  color: #96a0b5;
  font-size: 10px;
  font-weight: 300;
}

.sub-text-light {
  color: #96a0b5;
  font-size: 12px;
  font-weight: 300;
}

.sub-text-dark {
  color: #445880;
  font-size: 12px;
}

.title {
  font-weight: 500;
  font-size: 22px;
  color: #152c5b;
}

// ----------------------------
