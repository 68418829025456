.loaderContainer {
  position: relative;
  width: 200px;
  border-radius: 2px;
  height: 5px;
  background-color: #ddd;
  overflow: hidden;
}

.progressBar {
  position: absolute;
  border-radius: 2px;
  height: 100%;
  width: 50%;
  background-color: #1890ff;
  animation: moveBackAndForth 2s infinite;
}

@keyframes moveBackAndForth {
  0%,
  100% {
    transform: translateX(-20%);
  }
  50% {
    transform: translateX(120%);
  }
}
